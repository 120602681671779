import React from 'react';
import { graphql } from 'gatsby';
import AstroLink from "../lib/AstroLink";
import { Helmet } from "react-helmet";
import Img from "gatsby-image/withIEPolyfill"
import BackgroundImage from "gatsby-background-image";
import { slugify } from "../lib/helpers";
import Layout from "../Layout";
import { layoutOptions } from "../components/Content/RichText";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PortfolioCarouselImages from "../components/Portfolio/PortfolioCarouselImages";
import { ButtonArrowRight } from "../components/UI/Button";
import eye from "../images/icons/Eye.png";

const getTheme = theme => {

    console.log(theme);

    if (theme === "none") return null;

    switch (theme.toLowerCase()) {
        case "astro":
            return "background-gradient-astro";
        case "gun":
            return "background-gradient-gun";
        case "dusk":
            return "background-gradient-dusk";
        case "blood":
            return "background-gradient-blood";
        case "mustard":
            return "background-gradient-mustard";
        case "jungle":
            return "background-gradient-jungle";
        default:
            return "background-gradient-astro";
    }
}

const PortfolioDetailContent = ({ data, pageContext, location }) => {

    let content = data.contentfulPortfolioDetail;
    content = content.content.json
    const richText = documentToReactComponents(content, layoutOptions);

    const {
        entrySlug,
        entryClient,
        entryHeadline,
        entrySubHeadline,
        entryCategory,
        entryHeroImage,
        entryImages,
        entryTileImage,
        entryTopic,
        industry,
        role,
        theme
    } = data.contentfulPortfolioDetail;

    const prev = pageContext.prev ? {
        client: pageContext.prev.entryClient,
        description: pageContext.prev.entryDescription,
        image: pageContext.prev.entryTileImage,
        url: `/portfolio/${slugify(pageContext.prev.entryTopic)}/${slugify(pageContext.prev.industry)}/${pageContext.prev.entrySlug}`,
    }
        : null

    const next = pageContext.next ? {
        client: pageContext.next.entryClient,
        description: pageContext.next.entryDescription,
        image: pageContext.next.entryTileImage,
        url: `/portfolio/${slugify(pageContext.next.entryTopic)}/${slugify(pageContext.next.industry)}/${pageContext.next.entrySlug}`,
    }
        : null

    const titleClient = `${entryTopic} case study for ${entryClient}: ${entryHeadline}`;

    const categories = entryCategory.split(',')
        .map((category, i) => {
            return (
                <span key={i} className={`uk-text-nowrap uk-display-block`}>
                    {category}
                </span>
            )
        })

    const metaImage = `https://michaellisboa.com${entryTileImage.localFile.publicURL}`
    const metaUrl = `https://michaellisboa.com/portfolio/${slugify(entryTopic)}/${slugify(industry)}/${entrySlug}/`

    return (
        <Layout location={location}>
            <Helmet>
                <title>{titleClient}</title>
                <meta name="url" content={metaUrl} />
                <meta name="description" content={`${entrySubHeadline.entrySubHeadline}`} />
                <meta name="image" content={metaImage} />

                <meta property="og:url" content={metaUrl} />
                <meta property="og:title" content={titleClient} />
                <meta property="og:description" content={`${entrySubHeadline.entrySubHeadline}`} />
                <meta property="og:image" content={metaImage} />
                <meta property="og:type" content="article" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@Michael__Lisboa" />
                <meta name="twitter:title" content={titleClient} />
                <meta name="twitter:image" content={metaImage} />
                <meta name="twitter:description" content={`${entrySubHeadline.entrySubHeadline}`} />

                <link rel="canonical" href={metaUrl} />
            </Helmet>
            <BackgroundImage
                Tag="section"
                fluid={entryHeroImage.localFile.childImageSharp.fluid}
                preserveStackingContext={true}
                style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: `center center`,
                    height: "100%",
                    width: "100%"
                }}>
                <div className={`uk-section uk-section-large uk-light uk-margin-xxsmall`}>
                    <div className={`uk-height-min-large uk-flex uk-flex-center uk-flex-middle`}>
                        <div className="uk-container uk-width-1-2@m uk-padding-small">
                            <div className="">
                                <h1 className="h1__large">
                                    {entryHeadline}
                                </h1>
                                <p className="uk-text-lead">
                                    {entrySubHeadline.entrySubHeadline}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ opacity: "0.8", zIndex: -1 }} className={`${getTheme(theme)} uk-position-cover disable-select`} />
            </BackgroundImage>
            <PortfolioCarouselImages images={entryImages} />

            <section>
                {richText}
                <div className="uk-container uk-container-expand uk-padding-large uk-margin-large-top uk-flex uk-flex-center uk-background-astro-lightest">
                    <div className="uk-padding-small uk-width-1-2@m">
                        <div className="uk-grid uk-grid-small uk-grid-row-small uk-text-uppercase">
                            <div className="uk-width-2-3@m">
                                <div className="uk-margin-small-bottom">
                                    <div className="">
                                        <p className="uk-text-small uk-text-bolder uk-margin-remove">Client</p>
                                    </div>
                                    <div className="uk-width-expand">
                                        <p className="uk-text-small uk-text-astro uk-margin-remove">{entryClient}</p>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <p className="uk-text-small uk-text-bolder uk-margin-remove">Role</p>
                                    </div>
                                    <div className="uk-width-expand">
                                        <p className="uk-text-small uk-text-astro uk-margin-remove">{role}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-3@m uk-flex uk-flex-right@m">
                                <div className="">
                                    <div className="">
                                        <p className="uk-text-small uk-text-bolder uk-margin-remove">Services</p>
                                    </div>
                                    <div className="">
                                        <p className="uk-text-small uk-text-astro uk-margin-remove">{categories}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {nextCase(prev, next)}
        </Layout>
    );
}

export default PortfolioDetailContent;
export const portfolioDetailData = graphql`
    query portfolioDetailData($entrySlug: String) {
        contentfulPortfolioDetail(entrySlug: {
            eq: $entrySlug
        })  {
            entryClient
            entryDate
            entryDescription
            theme
            content {
                json
            }
            entryHeadline
            entrySubHeadline {
              entrySubHeadline
            }
            entryTopic
            industry
            role
            entryTitle
            entryCategory
            entryHeroImage {
              localFile {
                childImageSharp {
                    fluid(maxWidth: 2600, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
              }
            }
            entryTileImage {
              localFile {
                publicURL
                childImageSharp {
                    fluid(maxWidth: 1200, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
              }
            }
            entryImages {
                title
                description
              localFile {
                childImageSharp {
                    fluid(maxWidth: 1200, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
              }
            }
            entrySlug
          }
        
        allContentfulPortfolioDetail(sort: {
            fields: entryDate,
            order: DESC
        }) {
            edges {
                previous {
                    entrySlug
                    entryClient
                    entryDescription
                    entryTileImage {
                        fluid(maxWidth: 400) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                }
                next {
                    entrySlug
                    entryClient
                    entryDescription
                    entryTileImage {
                        fluid(maxWidth: 400) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                }
            }
        }
    }`
function nextCase(prev, next) {
    return <section className="uk-section uk-section-large uk-background-astro-lighter">
        <div className="uk-container uk-margin-large-bottom">
            <div className="uk-container uk-container-large">
                <div className="uk-width-1-2@s">
                    <p className="uk-h6 uk-text-muted uk-margin-remove-top uk-margin-small-bottom uk-text-uppercase">
                        <strong>&mdash;</strong> But wait, there's more
                    </p>
                    <h3 className="uk-margin-remove-top uk-display-block">
                        Check out a couple of other projects we've delivered.
                    </h3>
                </div>
            </div>
        </div>
        <div className="uk-container">
            <div className="uk-container uk-container-large">
                <div className={`uk-grid uk-grid-large uk-grid-row-small`}
                    data-uk-height-match="target: .uk-card-body">
                    <div className={`uk-width-1-2@s`}>
                        <div className="uk-card uk-card-small">
                            <div className="uk-height-medium uk-card-media-top uk-transition-toggle uk-position-relative uk-border-rounded uk-overlow-hidden">
                                <Img
                                    fluid={prev.image.localFile.childImageSharp.fluid}
                                    alt={`View ${prev.client} case`}
                                    imgStyle={{ borderRadius: "4px" }}
                                    objectFit="cover"
                                    opjectPosition="50% 50%"
                                    style={{ height: "100%" }} />
                                <AstroLink
                                    to={prev.url}
                                    className={`overlayBackground uk-transition-fade uk-position-cover uk-overlay uk-flex uk-flex-center uk-flex-middle`}>
                                    <img
                                        src={eye}
                                        alt={`View ${prev.client} case`}
                                        style={{ maxWidth: "72px" }} />
                                </AstroLink>
                            </div>
                            <div className="uk-card-body">
                                <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                                    <AstroLink
                                        className="uk-display-inline-block uk-link-text uk-link-reset"
                                        to={prev.url}
                                    >
                                        <strong>&mdash;</strong> {prev.client}
                                    </AstroLink>
                                </p>
                                <p className="uk-text-medium uk-text-bold uk-margin-remove-vertical">
                                    <AstroLink
                                        className="uk-link-text uk-link-reset"
                                        to={prev.url}
                                    >
                                        {prev.description}
                                    </AstroLink>
                                </p>
                                <ButtonArrowRight
                                    url={`${prev.url}`}
                                    label="View Case"
                                    css={`uk-margin-medium-top cta-button uk-text-astro-darker`}
                                    color={`rgba(44, 44, 43, 1)`} />
                            </div>
                        </div>
                    </div>

                    <div className={`uk-width-1-2@s`}>
                        <div className="uk-card uk-card-small">
                            <div className="uk-height-medium uk-card-media-top uk-transition-toggle uk-position-relative uk-border-rounded uk-overlow-hidden">
                                <Img
                                    fluid={next.image.localFile.childImageSharp.fluid}
                                    alt={`View ${next.client} case`}
                                    imgStyle={{ borderRadius: "4px" }}
                                    objectFit="cover"
                                    opjectPosition="50% 50%"
                                    style={{ height: "100%" }} />
                                <AstroLink
                                    to={next.url}
                                    className={`overlayBackground uk-transition-fade uk-position-cover uk-overlay uk-flex uk-flex-center uk-flex-middle`}>
                                    <img
                                        src={eye}
                                        alt={`View ${next.client} case`}
                                        style={{ maxWidth: "72px" }} />
                                </AstroLink>
                            </div>
                            <div className="uk-card-body">
                                <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                                    <AstroLink
                                        className="uk-display-inline-block uk-link-reset"
                                        to={next.url}
                                    >
                                        <strong>&mdash;</strong> {next.client}
                                    </AstroLink>
                                </p>
                                <p className="uk-text-medium uk-text-bold uk-margin-remove-vertical">
                                    <AstroLink
                                        className="uk-link-text uk-link-reset"
                                        to={next.url}
                                    >
                                        {next.description}
                                    </AstroLink>
                                </p>
                                <ButtonArrowRight
                                    url={`${next.url}`}
                                    label="View Case"
                                    css={`uk-margin-medium-top cta-button uk-text-astro-darker`}
                                    color={`rgba(44, 44, 43, 1)`} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="uk-container uk-container-small uk-margin-large uk-text-center">
            <ButtonArrowRight
                url={`/portfolio/`}
                label="See all cases"
                css={`cta-button uk-text-astro-darker`}
                color={`rgba(44, 44, 43, 1)`} />
        </div>
    </section>;
}

