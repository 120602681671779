import React from 'react';
import Img from "gatsby-image/withIEPolyfill"

const PortfolioCarouselImages = ({ images }) => 
        <div className="uk-container uk-container-expand uk-padding-remove uk-margin-xlarge-bottom uk-position-relative">
            <div className="js-slideshow-animation"
                data-uk-slider="pause-on-hover: false; autoplay: true; autoplay-interval: 5000;">
                <div>
                    <ul className="uk-slider-items uk-child-width-1-4@m">
                        {images.map((img, i) =>
                            <li key={`img-${i}`} className="uk-height-min-medium uk-width-3-5@m uk-margin-xxsmall-right">
                                <Img
                                    fluid={img.localFile.childImageSharp.fluid}
                                    alt={img.localFile.title}
                                    objectFit="cover"
                                    objectPosition="50% 50%"
                                    style={{ height: "100%" }}
                                />
                            </li>
                        )}
                    </ul>
                    <div
                        type="button"
                        className="uk-link-reset uk-slidenav uk-position-center-left uk-padding-small uk-background-muted uk-box-shadow-small uk-visible@s"
                        data-uk-slidenav-previous data-uk-slider-item="previous" />
                    <div
                        type="button"
                        className="uk-link-reset uk-slidenav uk-position-center-right uk-padding-small uk-background-muted uk-box-shadow-small uk-visible@s"
                        data-uk-slidenav-next data-uk-slider-item="next" />
                </div>
                <ul className="uk-slider-nav uk-dotnav uk-flex uk-flex-center uk-margin-top" />
            </div>
        </div>
export default PortfolioCarouselImages;
